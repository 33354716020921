import React from 'react';
import config from "../../config.json"
import { useNavigate } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import "./navfooter.css";

export default function NavFooter() {
    const navigate = useNavigate(); 

    return (
        <div className='nav-footer'>
            <div className='row space-between'>
                <div className='col'>
                    <span className='ext-link-header'>Legal</span>
                    <a onClick={() => navigate("/terms-and-conditions")} className='ext-link'>
                        <OpenInNewIcon style={{fontSize:'1em', marginRight:"5px"}}/> Terms & Conditions
                    </a>
                    <a onClick={() => navigate("/cookie-policy")} className='ext-link'>
                        <OpenInNewIcon style={{fontSize:'1em', marginRight:"5px"}}/> Cookie Policy
                    </a>
                    <a onClick={() => navigate("/privacy-policy")} className='ext-link'>
                        <OpenInNewIcon style={{fontSize:'1em', marginRight:"5px"}}/> Privacy Policy
                    </a>
                </div>
                <div className='col vert-space-between'>
                    <InstagramIcon style={{fontSize:'1.3em', cursor:"pointer"}}/> 
                    <FacebookIcon style={{fontSize:'1.3em', cursor:"pointer"}}/>
                    <YouTubeIcon style={{fontSize:'1.3em', cursor:"pointer"}}/>
                </div>
            </div>
            <p className='copyright'>
                © {config.site.url}, All Rights Reserved.
            </p>
        </div>
    )
}

import React from 'react'

export default function Spacer({height=0, width=0}) {
    return (
        <div style={{
            height,
            width,
            background:"white"
        }}></div>
    )
}

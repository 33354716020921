import './App.css';
import React from 'react';
import { 
	BrowserRouter as Router,
	Routes,
	Route
} from 'react-router-dom';
import NavBar from './components/utils/NavBar';
import HomePage from './components/HomePage';
import ContactPage from './components/ContactPage';
import NavFooter from './components/utils/NavFooter';
import AboutPage from './components/AboutPage';
import PricingPage from './components/PricingPage';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import TermsAndConditions from './components/legal/TermsAndConditions';
import CookiePolicy from './components/legal/CookiePolicy';
import PrivacyPolicy from './components/legal/PrivacyPolicy';


const darkTheme = createTheme({
	palette: {
		mode: 'dark',
	},
});
const lightTheme = createTheme({
	palette: {
		mode: "light"
	}
});

function App() {

	
	return (
		<ThemeProvider theme={lightTheme}>
			<CssBaseline/>
			<Router>
				<NavBar/>
				<Routes>
					{/* BASE ROUTES */}
					<Route 
						path='/' 
						element={<HomePage/>} 
					/>
					<Route 
						path='/contact' 
						element={<ContactPage/>} 
					/>
					<Route
						path='/about'
						element={<AboutPage/>}
					/>
					<Route
						path='/pricing'
						element={<PricingPage/>}
					/>
					{/* LEGAL */}
					<Route
						path='/terms-and-conditions'
						element={<TermsAndConditions/>}
					/>
					<Route
						path='/cookie-policy'
						element={<CookiePolicy/>}
					/>
					<Route
						path='/privacy-policy'
						element={<PrivacyPolicy/>}
					/>
				</Routes>
				<NavFooter/>
			</Router>
		</ThemeProvider>
	);
}

export default App;

import React from 'react';
import "./section.css"

export default function Section({children, direction="row", className="", style={}}) {
    return (
        <div className={`section ${direction} ${className}`} style={style}>
            {children}
        </div>
    )
}

import React, { useEffect, useState } from 'react';
import { ButtonGroup, Button } from '@mui/material';
import config from "../config.json";
import "./pricing-page.css";
import { scrollToTop } from '../utils/scroll';
import Section from "./utils/Section";
import CheckIcon from '@mui/icons-material/Check';
import Announcement from './utils/Announcement';


function PriceCard({plan, price, features}){
    return (
        <div className='price-card'>
            <div className='plan'>
                <h2><span>{plan}</span></h2>
            </div>
            <div className='price'>
                $<span>{price}</span>/mo
            </div>
            <div className='features'>
                {features.map((feature, index) => {
                    return (
                        <p className='feature' key={`feature-${index}`}>
                            <CheckIcon style={{fontSize:'1.2em', color:"var(--secondary-color-bg)", marginRight:"10px"}}/> {feature}
                        </p>
                    )
                })}
                <Button variant='text' color="success">
                    Purchase
                </Button>
            </div>
        </div>
    )
}

export default function PricingPage() {
    const [billingCycle, setBillingCycle] = useState("monthly");

    useEffect(scrollToTop, []);

    return (
        <div className='pricing-page'>
            <Announcement isVisible={true}/>
            <Section style={{margin:"30px"}}>
                <h1 className='header'>Pricing Plans</h1>
            </Section>
            <Section>
                <ButtonGroup>
                    <Button onClick={() => setBillingCycle("monthly")} variant={billingCycle === "monthly" ? "contained" : "outlined"} color='success'>Monthly</Button>
                    <Button onClick={() => setBillingCycle("annually")} variant={billingCycle === "annually" ? "contained" : "outlined"} color='success'>Annually</Button>
                </ButtonGroup>
            </Section>
            <Section className='price-card-container space-between'>
                {config.pricing.plans.map((data,index) => {
                    return (
                        <PriceCard
                            plan={data.plan}
                            price={data[billingCycle]}
                            features={data.features}
                        />
                    )
                })}
            </Section>
            <Section>
                <p className='pricing-disclaimer'>
                    <b style={{color:"darkred"}}>*</b> Employee accounts that exceed plan alotment are charged at <wbr/> <b>${config.pricing.exceeder} / employee / mo</b>
                </p>
            </Section>
        </div>
    )
}

/*

Basic:
    Monthly: $5/mo
    Annually: $3/mo
    Features: [
        5 employee accounts
    ]

Pro:
    Monthly: $15/mo
    Annually: $10/mo
    Features: [
        20 employee accounts
    ]

Enterprise:
    Monthly: $30/mo
    Annually: $20/mo
    Features: [
        50 employee accounts
    ]
*/
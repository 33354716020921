import React, { useEffect, useRef } from 'react';
import "./about-page.css";
import Section from './utils/Section';
import AddMarkerImage from "../images/iphone-mockups/add-marker.png";
import MarkerListImage from "../images/iphone-mockups/list-markers.png";
import MapViewImage from "../images/iphone-mockups/map-view-1.png";
import NotificationsImage from "../images/iphone-mockups/notifications.png";
import SettingsImage from "../images/iphone-mockups/settings.png";
import ViewMarkerImage from "../images/iphone-mockups/view-marker.png";
import Spacer from './utils/Spacer';
import { scrollToTop } from '../utils/scroll';

const aboutData = [
    {
        imageSrc: MapViewImage,
        header: "View Your Markers",
        paragraph: "Powered by Google Maps™, our map view allows you to see all of your markers at once, wherever they may be." 
    },
    {
        imageSrc: ViewMarkerImage,
        header: "View Marker Details",
        paragraph: "Easily store and see details about your markers."
    },
    {
        imageSrc: AddMarkerImage,
        header: "Add a Marker",
        paragraph: "Easily add an unlimited amount of markers! Either enter the address, or press and hold on the map."
    },
    {
        imageSrc: MarkerListImage,
        header: "Find the Closest Marker",
        paragraph: "Our marker list view makes it easy to find the closest dump site."
    },
    {
        imageSrc: NotificationsImage,
        header: "Stay Notified",
        paragraph: "Get notified when someone creates a marker, or if someone is trying to join your organization."
    },
    {
        imageSrc: SettingsImage,
        header: "Manage your Organization",
        paragraph: "Easily manage your markers, who is in your organization, and other preferences."
    }
]   

function AboutSection({imageSrc, header, paragraph, layout="left"}){
    if (window.innerWidth <= 800){
        layout = "left"
    }

    if (layout === "left") return (
        <Section style={{marginTop:"50px"}}>
            <div className='col'>
                <h1 className='header'> {header} </h1>
                <p className='paragraph'> {paragraph} </p>
            </div>
            <div>
                <img src={imageSrc} className='about-image' alt="iphone-mockup"/>
            </div>
        </Section>
    )

    return (
        <Section style={{marginTop:"50px"}}>
            <div>
                <img src={imageSrc} className='about-image' alt="iphone-mockup"/>
            </div>
            <div className='col' style={{marginLeft:"50px", marginRight:"0px"}}>
                <h1 className='header' style={{textAlign:"left"}}> {header} </h1>
                <p className='paragraph' style={{textAlign:"left"}}> {paragraph} </p>
            </div>
        </Section>
    )
}

export default function AboutPage() {
    
    useEffect(scrollToTop,[]);

    return (
        <div className='about-page'>
            {aboutData.map((data,index) => {
                const {imageSrc, header, paragraph} = data;
                const layout = index%2===0 ? "left" : "right";
                
                return (
                    <AboutSection
                        key={`about-section-${index}`}
                        imageSrc={imageSrc}
                        header={header}
                        paragraph={paragraph}
                        layout={layout}
                    />
                )
            })}
            <Spacer height={100}/>
        </div>
    )
}

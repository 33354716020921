import React, {useEffect} from 'react'
import "./home-page.css";
import config from "../config.json"
import iphoneMapViewMockupImage from "../images/iphone-mockups/map-view-portrait.png";
import ForestIcon from '@mui/icons-material/Forest';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import Spacer from './utils/Spacer';
import Card, {CardHeader, CardText, CardButton} from './utils/Card'
import { useNavigate } from 'react-router-dom';
import { scrollToTop } from '../utils/scroll';


function SectionOne(){
    return (
        <div className='section-one'>
            <div style={{margin:"50px 50px 30px 50px"}}>
                <h1 className='motto'>Log On,<br/> Drop Off, <br/> Grow Together.</h1>
                <p className='mission-statement'>
                    Our mission is to make your life easier. We help arborists locate and keep track of their dump sites through an <wbr/> easy to use and simple app.
                    {/* Our mission is to make your life easier. 
                    <br/>
                    We help arborists locate and 
                    <br/> 
                    keep track of their dump sites 
                    <br/> 
                    through an easy to use, simple app.  */}
                </p>
            </div>
            <div>
                <img className='iphone-mockup-image' src={iphoneMapViewMockupImage} />
            </div>
        </div>
    )
}

function SectionTwo(){
    return (
        <>
            <div className='section-two-bg-transition-div'></div>
            <div className='section-two'>
                <ForestIcon style={{fontSize:"5em"}}/> 
                <h1 className='header'>
                    For Arborists. <wbr/> By Arborists. 
                </h1>
                <p className='mission-statement'>
                    {config.brand.name} was built  for arborists, by arborists. {/*<br/>*/}
                    It was designed from the ground up with simplicity in mind, {/*<br/>*/}
                    while still ensuring it contained all of the features necessary {/*<br/>*/}
                    to make your job easier. 
                </p>
                <Spacer height={50}/>
                <MoreTimeIcon style={{fontSize:"5em"}}/>
                <h1 className='header'>
                    Built for Time Savings
                </h1>
                <p className='mission-statement'>
                    {config.brand.name} was built for the sole purpose of saving you time and hassle.
                    Having your dumping locations organized at the tip of your fingers has been proven to help Arborists stay coordinated and efficient. 
                </p>
            </div>
            <div className='section-two-bg-transition-div reverse'></div>
        </>
    )
}

function SectionThree({navigate}){
    return (
        <>
            <div className='section-three'>
                <Card>
                    <CardHeader> How does it work? </CardHeader>
                    <CardText> {config.brand.name} offers a wide variety of powerful features that make keeping track of your dump sites simple and easy.</CardText>
                    <CardButton onClick={() => navigate("/about")}>Learn More</CardButton>
                </Card>
                <Spacer height={75}/>
                <Card>
                    <CardHeader> Affordable Pricing </CardHeader>
                    <CardText> Our pricing model is simple – pay for what you use.</CardText>
                    <CardButton onClick={() => navigate("/pricing")}>Learn More</CardButton>
                </Card>
            </div>
            <Spacer height={50}/>
            {/* <div className='section-two-bg-transition-div'></div> */}
        </>
        
    )
}

export default function HomePage() {
    const navigate = useNavigate()

    useEffect(scrollToTop,[]);

    return (
        <div className='home-page'>
            <SectionOne/>
            <SectionTwo/>
            <SectionThree navigate={navigate}/>
        </div>
    )
}

import React, { useState, useEffect } from 'react';
import { TextField, Button } from '@mui/material';
import { scrollToTop } from '../utils/scroll';
import config from "../config.json";
import Spacer from "../components/utils/Spacer";
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import "./contact.css"

function ContactPageInteractive() {
    const emailValidRegex = new RegExp(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [emailWarning, setEmailWarning] = useState("");
    
    useEffect(scrollToTop, []);

    const testEmail = () => {
        const isValid = emailValidRegex.test(email);
        if (!isValid) {
            setEmailWarning("Invalid Email")
        }
        else {
            setEmailWarning("");
        }
    }

    return (
        <div className='contact-page'>
            <h1 className='header'> Contact </h1>
            <div className='first-last-name'> 
                <TextField
                    variant='outlined'
                    label='First Name'
                    className='text-field'
                    onChange={e=>setFirstName(e.target.value)}
                    required
                />
                <TextField
                    variant='outlined'
                    label='Last Name'
                    className='text-field'
                    onChange={e=>setLastName(e.target.value)}
                    required
                />
            </div>
            <div className='email'>
                <TextField
                    variant='outlined'
                    label='Email'
                    className='text-field'
                    onChange={e=>setEmail(e.target.value)}
                    onBlur={testEmail}
                    error={!!emailWarning}
                    helperText={emailWarning}
                    required
                />
            </div>
            <div className='message'>
                <TextField
                    variant='outlined'
                    label='Message'
                    className='text-field'
                    onChange={e=>setMessage(e.target.value)}
                    required
                    multiline
                    minRows={5}
                />
            </div>
            <Button 
                variant='contained' 
                color='success' 
                disabled={!firstName || !lastName || !email || !message}
                style={{margin:"30px"}}
            >
                Submit
            </Button>
        </div>
    )
}

export default function ContactPage(){
    return (
        <div className='contact-page'>
            <h1 className='header'> Get in Touch! </h1>
            <Spacer height={50}/>
            <div className='contact-method-div'>
                <EmailIcon style={{fontSize:"3em"}}/>
                Contact us by email at 
                <br/> 
                <a className='contact-anchor' href={`mailto:${config.contact.email}`}><b>{config.contact.email}</b></a>
            </div>
            <Spacer height={50}/>
            <div className='contact-method-div'>
                <PhoneIcon style={{fontSize:"3em"}}/>
                Call us at 
                <br/> 
                <a className='contact-anchor' href={`tel:${config.contact.phone}`}><b>{config.contact.phone}</b></a>
            </div>
            <Spacer height={30}/>
            <h2 className='header'> Or Contact us on Social Media! </h2>
            <div className='contact-method-div-row' style={{width:"300px", justifyContent:"space-evenly"}}>
                <FacebookIcon  className='social-media-icon'  onClick={() =>  window.open(config.contact.facebook, '_blank')}/>
                <InstagramIcon className='social-media-icon'  onClick={() =>  window.open(config.contact.instagram, '_blank')}/>
                <TwitterIcon   className='social-media-icon'  onClick={() =>  window.open(config.contact.twitter, '_blank')}/>
            </div>
        </div>
    )
}

import React from 'react';
import "./card.css";
import { Button } from '@mui/material';

export function CardHeader({children, style, className=""}){
    return (
        <h1 className={`card-header ${className}`} style={{...style}}>
            {children}
        </h1>
    )
}

export function CardText({children, style, className=""}){
    return (
        <p className={`card-text ${className}`} style={{style}}>
            {children}
        </p>
    )
}

export function CardButton({children, style, className="", color="success", onClick=()=>{}}){
    return (
        <Button variant='contained' color={color} style={{...style}} className={`card-button ${className}`} onClick={onClick}>
            {children}
        </Button>
    )
}

export default function Card({children, style, className=""}) {
    return (
        <div className={`card ${className}`} style={{...style}}>
            {children}
        </div>
    )
}

import React, {useEffect, useRef} from 'react'
import { scrollToTop } from '../../utils/scroll';

const cookiePolicyHTML = `
    <style>
    body {
        font-family: 'Arial', sans-serif;
        line-height: 1.6;
        margin: 20px;
    }
    h1 {
        color: #333;
    }
    p {
        color: #555;
    }
    </style>
    <h1>Cookie Policy</h1>

    <p><em>Last Updated: Thursday, November 16th, 2023.</em></p>

    <p>This Cookie Policy explains how Chip Sync <a href="www.chipsync.io">(www.chipsync.io)</a> ("we," "us," or "our") uses cookies on our website or application ("the Service"). By using the Service, you consent to the use of cookies as described in this policy.</p>

    <h2>What Are Cookies?</h2>

    <p>Cookies are small pieces of text sent by your web browser by a website you visit. A cookie file is stored in your web browser and allows the Service or a third-party to recognize you and make your next visit easier and more useful to you.</p>

    <h2>How We Use Cookies</h2>

    <p>We use cookies for the following purposes:</p>
    <ol>
        <li><strong>Authentication:</strong> To recognize you when you visit and navigate our website.</li>
        <li><strong>Security:</strong> To prevent fraudulent activities and enhance security.</li>
        <li><strong>Preferences:</strong> To remember your preferences and settings.</li>
        <li><strong>Analytics:</strong> To analyze how you use the Service and improve its functionality.</li>
    </ol>

    <h2>Third-Party Cookies</h2>

    <p>We may also use third-party services that use cookies. These third-party service providers have their own privacy policies addressing how they use such information.</p>

    <h2>Your Choices</h2>

    <p>You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser settings to decline cookies if you prefer. However, this may prevent you from taking full advantage of the Service.</p>

    <h2>Changes to This Policy</h2>

    <p>We may update our Cookie Policy from time to time. We will notify you of any changes by posting the new policy on this page.</p>

    <h2>Contact Us</h2>

    <p>If you have any questions about our Cookie Policy, please contact us at <a href="mailto:support@chipsync.io">support@chipsync.io</a> </p>

    <footer>
        <p>Tarrant Munson Enterprises LLC<br>
        17 Lake Shore Road, Putnam Valley, New York, 10579<br>
        support@chipsync.io<br>
        1+(631)-318-0947</p>
    </footer>
`

export default function CookiePolicy() {
    const ref = useRef(null); 

    useEffect(() => {
        scrollToTop()
        ref.current.innerHTML = cookiePolicyHTML; 
    })

    return (
        <div ref={ref} style={{padding:"50px", marginTop:"50px", background:"white"}}>CookiePolicy</div>
    )
}

import logo from "../../images/logo/logo.png";
import React from 'react'
import "./logo.css"


export default function Logo({style, size="small", animation="", shadow=false}) {
    return (
        <img 
            id="logo"
            className={`${size} ${animation} ${shadow ? "shadow" : ""}`}
            src={logo}
            alt="Logo"
            style={{
                ...style
            }}
        />
    )
}

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { CssBaseline, Slide } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import config from "../../config.json";
import Logo from './Logo';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { useNavigate } from 'react-router-dom';

import "./navbar.css";


const pages = config.site.pages;

function HideOnScroll(props) {
	const { children, window } = props;
	// Note that you normally won't need to set the window ref as useScrollTrigger
	// will default to window.
	// This is only being set here because the demo is in an iframe.
	const trigger = useScrollTrigger({
	  target: window ? window() : undefined,
	});
  
	return (
	  <Slide appear={false} direction="down" in={!trigger}>
		{children}
	  </Slide>
	);
  }

export default function NavBar(props) {
	const navigate = useNavigate(); 

	const [anchorElNav, setAnchorElNav] = React.useState(null);

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleClickMenuItem = page => {
		handleCloseNavMenu();
		navigate(page);
	}

	return (
		<>
			<CssBaseline/>
			<HideOnScroll {...props}>
				<AppBar className='navbar'>
					<Container maxWidth="none">
						<Toolbar disableGutters>
							{/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
							<IconButton sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} onClick={() => navigate("/")}>
								<Logo size='small'/>
							</IconButton>
							<Typography
								onClick={() => navigate("/")}
								variant="h6"
								noWrap
								component="a"
								className='brand-name-font'
								sx={{
									mr: 2,
									display: { xs: 'none', md: 'flex' },
									fontWeight: 700,
									letterSpacing: '.2rem',
									fontSize:"2em",
									color: 'inherit',
									textDecoration: 'none',
									cursor:"pointer"
								}}
							>
								{config.brand.name.toUpperCase()}
							</Typography>

							<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
								<IconButton
									size="large"
									aria-label="account of current user"
									aria-controls="menu-appbar"
									aria-haspopup="true"
									onClick={handleOpenNavMenu}
									color="inherit"
								>
									<MenuIcon />
								</IconButton>
								<Menu
									id="menu-appbar"
									anchorEl={anchorElNav}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'left',
									}}
									keepMounted
									transformOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}
									open={Boolean(anchorElNav)}
									onClose={handleCloseNavMenu}
									sx={{
										display: { xs: 'block', md: 'none' },
									}}
								>
									{pages.map((page) => (
										<MenuItem key={page} onClick={() => handleClickMenuItem(page.toLowerCase())}>
											<Typography textAlign="center">{page}</Typography>
										</MenuItem>
									))}
								</Menu>
							</Box>
							<IconButton sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} onClick={() => navigate("/")}>
								<Logo size='small'/>
							</IconButton>
							<Typography
								variant="h6"
								noWrap
								component="a"
								className='brand-name-font'
								onClick={() => navigate("/")}
								sx={{
									mr: 2,
									display: { xs: 'flex', md: 'none' },
									flexGrow: 1,
									fontWeight: 700,
									letterSpacing: '.2rem',
									fontSize:"2em",
									color: 'inherit',
									textDecoration: 'none',
									cursor: "pointer"
								}}
							>
								{config.brand.name.toUpperCase()}
							</Typography>
							<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent:'space-evenly' }}>
								{pages.map((page) => (
									<Button
										key={page}
										onClick={() => handleClickMenuItem(page.toLowerCase())}
										sx={{ my: 2, color: 'white', display: 'block'}}
									>
										{page}
									</Button>
								))}
							</Box>
						</Toolbar>
					</Container>
				</AppBar>
			</HideOnScroll>
		</>
	);
}


import React from 'react';
import "./announcement.css"; 
import config from "../../config.json";
import { Button } from '@mui/material';

const Break = ({spaces=1}) => {
    return <>
        {Array.from(new Array(spaces)).map(() => <br/>)}
    </>
}

export default function Announcement({isVisible}) {
    function signUpRedirect(){
        window.open(config.product.url, '_blank');
    }
    
    if (!isVisible) return <></>

    return (
        <div className='announcement-container'>
            <div className='announcement'>
                <h1 className='header'>{config.brand.name} is free for limited time!</h1>
                <p className='text'> 
                    We're excited to announce that Chip Sync is now available for free use for the next three months as part of our beta release phase!    
                    <Break spaces={2}/>
                    This special offering allows you to explore and experience all the features and functionalities we have carefully crafted for our users. 
                    <Break spaces={2}/>
                    During this period, you'll have the opportunity to provide valuable feedback, helping us refine and enhance the app to meet your needs and expectations. 
                    <Break spaces={2}/>
                    Your input is instrumental in shaping the final version of our app, and we greatly appreciate your involvement in this journey. So, dive in, make the most of this exclusive free access, and let us know what you think—your insights will be pivotal in making our app even better! 
                </p>
                {/* <h2 className='header'>Become a Design Partner Today!</h2> */}
                <Button 
                    color='success' 
                    variant='contained' 
                    style={{margin:"20px"}}
                    onClick={signUpRedirect}
                >Sign Up</Button>
            </div>
        </div>
    )
}
